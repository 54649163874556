import { createApp } from 'vue'
import App from './App.vue'
import './assets/style.css'
import router from './router'
import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            language: 'RO'
        }
    },
    mutations: {
        changeLanguage (state, payload) {
            state.language = payload;
        }
    },
    getters: {
        language (state) {
            return state.language
        }
    }
})

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')
