<template>
    <div class="bg-teilor w-full h-3 absolute top-0 z-10 hidden desktop:block" />
    <Banner />
    <router-view />
</template>

<script>
    import Banner from './components/Banner/Banner';
    import {mapGetters} from "vuex";
    // import TeilorAboutUs from './components/Teilor/1AboutUs';
    // import History from './components/Teilor/2History';
    // import MapOfStories from './components/Teilor/3MapOfStories';
    // import Teilor from './components/Teilor'

    export default {
        name: 'App',
        components: {
            Banner,
            // Teilor
            // TeilorAboutUs,
            // History,
            // MapOfStories
        },

        computed: {
            ...mapGetters(['language'])
        },

        mounted () {

            setTimeout(() => {
                // if (this.$route.params.language != this.language) this.$store.commit('changeLanguage', this.$route.params.language.toUpperCase());

                if (this.$route.path == '/' && this.$route.fullPath == '/') this.$router.push(`/${this.language}/Teilor`);
                else if (this.$route.params.language == this.$route.params.language.toLowerCase()) {
                    var hash = this.$route.hash;
                    this.$router.push({ name: 'teilor', params: { language: this.language }, hash })
                    // this.$router.push(`/${this.language}/Teilor`);
                }
            }, 0);

        }
    }
</script>

<style>
.external-link {
    @apply text-teilor;
    text-decoration: underline;
    cursor: pointer;
}
    #app {

    /*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*  -webkit-font-smoothing: antialiased;*/
    /*  -moz-osx-font-smoothing: grayscale;*/
    /*  text-align: center;*/
    /*  color: #2c3e50;*/
    /*  margin-top: 60px;*/
    }
</style>
