<template>
    <div id="AboutUs" class="desktop:block flex flex-col">
        <div class="flex">
            <div class="top-left hide hidden desktop:block" />
            <div class="top-left mobile desktop:hidden link-title flex items-center justify-center MontserratMedium">
                <span>TEILOR</span>
            </div>

            <div class="top-right pl-6 link-title MontserratMedium">
                <span class="hidden desktop:inline">TEILOR</span>
                <span class="hidden desktop:block h-4 w-2px bg-black mx-2" />
                <span class="text-teilor">ABOUT US</span>
            </div>
        </div>

        <div class="flex desktop:flex-row flex-col">
            <div class="left relative z-10">
                <div class="img-container">
<!--                    <img src="../../assets/teilor1.png" alt="">-->
                    <img src="../../assets/ring1.png" alt="">
                </div>
            </div>

            <div class="flex desktop:hidden">
                <div class="bottom-left" />
                <div class="bottom-right" />
            </div>

            <div class="right flex items-end justify-center">
                <div class="relative max-w-tablet z-10 text-content text-lg MontserratLight">
                    <span class="absolute page-number">01</span>
                    <p class="my-6">
                        TEILOR is the largest fine jewelry chain in Romania with 56 brick-and-mortar stores, out of
                        which six stores are TEILOR Exclusive, offering clients limited series of handmade and carefully
                        selected jewelry inlaid with diamonds and precious stones. By the end of 2021, the TEILOR network
                        will reach 62 stores, of which 53 are based in Romania, four in Poland, three in Bulgaria, and
                        two in Hungary. Besides the investments in brick-and-mortar stores, the company is considering
                        additional investments to develop its online platforms further to enhance customers' omnichannel experience.
                    </p>
                    <p class="my-6">
                        „TEILOR” translates for Lindens’, embodying the passion for jewellery, as the delicate flowers
                        and unique scent perfectly reflect TEILOR’s brand essence.
                    </p>
                    <p class="my-6">
                        Through the strategy, TEILOR appeals to all ranges and ages, meantime being a source of continuous
                        inspiration for future generations. Our fine jewellery collections range from mid-priced small
                        items up to precious and exclusive pieces, serving the desires of all categories of customers.
                    </p>
                    <p class="my-6">
                        TEILOR's vision translates into an extremely rapid evolution, and the team's ideal is dedicated
                        to the world: to become a global brand in the jewellery landscape. We see jewellery - our core
                        and soul - as an intimate part of gratitude and self-care.
                    </p>
                    <p>
                        Our mission is to empower people to see the impossible and go beyond. We do this by infusing
                        confidence in them, by inspiring and motivating them to become their best version. We have a
                        true understanding of what our team and our customers need and we anticipate and deliver the
                        most memorable experiences, in the most relevant and authentic way.
                    </p>
                </div>
            </div>
        </div>

        <div class="hidden desktop:flex">
            <div class="bottom-left" />
            <div class="bottom-right" />
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            // this.$nextTick(() => {
            //     // console.log(this.$route.hash);
            //     const el = document.querySelector(this.$route.hash);
            //     const elementPosition = el.getBoundingClientRect().top;
            //     const elDistanceToTop = window.pageYOffset;
            //     const offsetPosition = elDistanceToTop + elementPosition;
            //     window.scrollTo({
            //         top: offsetPosition,
            //         behavior: "smooth"
            //     });
            //     // console.log(el);
            //     // el && el.scrollIntoView({ behavior: "smooth" });
            // })
        }
    }
</script>

<style scoped lang="scss">
    .left {
        @apply bg-darkGray;
        width: 100%;
        @screen desktop {
            width: 40%;
        }
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .img-container {
            height: 100%;
            transform: translate(-2%, 0);
            @screen desktop {
                transform: translate(15%, 0);
            }
            &::before {
                content: '';
                position: absolute;
                bottom: -7px;
                right: -7px;
                height: 7px;
                width: 250px;
                @apply bg-teilor;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: -7px;
                width: 7px;
                height: 150px;
                @apply bg-teilor;
            }
        }
        img {
            object-fit: cover;
            margin-left: auto;
            width: 98%;
            @screen desktop {
                margin-left: unset;
                height: 100%;
                width: unset;
            }
        }
    }

    .right {
        @apply bg-light-gray;
        padding: 20px 6vw;
        width: 100%;
        @screen desktop {
            width: calc(60% - 1.5rem);
            padding: unset;
        }
        .text-content {
            @screen desktop {
                margin-right: calc(min(5vw, 15%));
                margin-left: 20%;
            }
        }
    }

    .top-right, .top-left,.bottom-left, .bottom-right {
        height: 60px;
    }
    .top-left, .bottom-left {
        width: 40%;
        @apply bg-darkGray;
    }

    .top-right, .bottom-right {
        width: calc(60% - 1.5rem);
        background-color: white;
    }

    .page-number {
        font-size: 200px;
        line-height: 200px;
        @apply text-number-gray;
        top: -100px;
        right: 0;
        @screen desktop {
            right: -50px;
        }
        z-index: -1;
    }
    p {
        text-align: justify;
    }
</style>