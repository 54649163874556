<template>
    <div id="rapoarte_curente" class="main-container">
        <h2 v-if="language.toUpperCase() == 'RO'" class="text-teilor MontserratMedium">Rapoarte curente</h2>
        <h2 v-else class="text-teilor MontserratMedium">Current Reports</h2>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            Aici investitorii pot găsi toate rapoartele curente emise de companie cu privire la evenimentele importante
            ale companiei Teilor Holding S.A.
        </p>
        <p class="MontserratLight" v-else>
            Here investors can find all the current reports issued by the company regarding the important events of
            Teilor Holding S.A.
        </p>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        }
        // name: '',
        // components: '',
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {},
        // methods: {}
    };
</script>

<style scoped lang='scss'>

</style>
