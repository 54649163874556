<template>
    <div id="MGHistory" class="my-32 desktop:block flex flex-col">
        <div class="flex">
            <div class="top-left justify-end link-title mr-6 MontserratMedium">
                <span class="desktop:hidden">MONEYGOLD</span>
                <span class="hidden desktop:inline">MONEYGOLD</span>
                <span class="hidden desktop:block h-4 w-2px bg-black mx-2" />
                <span class="text-teilor hidden desktop:inline">HISTORY</span>
            </div>
            <div class="top-right hidden desktop:block" />
            <div class="top-right desktop:hidden link-title flex items-center justify-center MontserratMedium">
                <span class="text-teilor">HISTORY</span>
            </div>
        </div>

        <div class="flex desktop:flex-row flex-col">
            <div class="right flex desktop:hidden relative z-10">
                <div class="img-container">
                    <img src="../../assets/mg2.png" alt="">
                </div>
            </div>

            <div class="flex desktop:hidden">
                <div class="bottom-left mr-6" />
                <div class="bottom-right" />
            </div>

            <div class="left flex items-end justify-center">
                <div class="relative max-w-tablet z-10 text-content text-lg MontserratLight">
                    <span class="absolute page-number">02</span>
                    <p class="my-6">
                        Moneygold started its activity in 2004 by opening the first agency in Pitesti.
                        The national expansion started in 2010, when the agencies in other cities were opened.
                    </p>
                    <p class="my-6">
                        Currently, Moneygold has a network of over 70 locations around the country: București,
                        Craiova, Călărași, Pitești, Mioveni, Câmpulung, Curtea de Argeș, Topoloveni, Râmnicu Sărat,
                        Roman, Buzău, Constanța, Fetești, Focșani, Găești, Pașcani, Ploiești, Moreni, Sibiu, Slobozia,
                        Suceava, Țăndărei, Târgoviște, Urziceni, Vaslui. This way our customers have an easy access to
                        our pawning services, jewelry sales, money exchange and money transfer services.
                    </p>
                </div>
            </div>

            <div class="right hidden desktop:flex relative z-10">
                <div class="img-container">
                    <img src="../../assets/mg2.png" alt="">
                </div>
            </div>
        </div>

        <div class="hidden desktop:flex">
            <div class="bottom-left mr-6" />
            <div class="bottom-right" />
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            // this.$nextTick(() => {
            //     console.log(this.$route.hash);
            //     const el = document.querySelector(this.$route.hash);
            //     console.log(el);
            //     el && el.scrollIntoView({ behavior: "smooth" });
            // })
        }
    }
</script>

<style scoped lang="scss">
.right {
    @apply bg-darkGray;
    width: 100%;
    @screen desktop {
        width: 40%;
    }
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    .img-container {
        height: 100%;
        transform: translate(2%, 0);
        @screen desktop {
            transform: translate(-15%, 0);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: -7px;
            height: 7px;
            width: 250px;
            @apply bg-teilor
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -7px;
            width: 7px;
            height: 150px;
            @apply bg-teilor
        }
    }
    img {
        object-fit: cover;
        width: 98%;
        @screen desktop {
            height: 100%;
            width: unset;
        }
    }
}
.left {
    padding: 20px 6vw;
    @apply bg-light-gray;
    width: 100%;
    @screen desktop {
        padding: unset;
        width: calc(60% - 1.5rem);
    }
    .text-content {
        @screen desktop {
            margin-right: 20%;
            margin-left: calc(min(5vw, 15%));
        }
    }
}
.top-left, .top-right,.bottom-right, .bottom-left {
    height: 60px;
}
.top-right, .bottom-right {
    width: 40%;
    @apply bg-darkGray;
}

.top-left, .bottom-left {
    width: calc(60% - 1.5rem);
    @screen desktop {
        width: calc(60% - 3rem);
    }
}

.page-number {
    font-size: 200px;
    line-height: 200px;
    @apply text-number-gray;
    top: -100px;
    left: 0;
    @screen desktop {
        left: -50px;
    }
    z-index: -1;
}
p {
    text-align: justify;
}
</style>