<template>
    <nav class="relative">
 <!-- @click="$store.commit('TOGGLE_SIDEBAR')" -->
        <div class="mobilenav" :class="{ 'active': scrolled }">
            <div class="burgerMenu inline-block" @click="$emit('toggle_sidebar')" :class="{ 'menuOpen': sidebar_is_open }">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>
        </div>
    </nav>
</template>

<script>
    // import {ref} from "vue";

    export default {
        props: {
            sidebar_is_open: {
                type: Boolean,
                required: true,
                default: false
            }
        },
        data: () => ({
            scrolled: false
        }),

        mounted () {
            // const scrollFn = () => {
            //     console.log(document.body.scrollTop);
            // }
            // const body = document.querySelector('body');
            // console.log('body', body);
            window.addEventListener('scroll', this.scrollFn);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.scrollFn);
        },
        methods: {
            scrollFn () {
                if (window.scrollY > 0 && !this.scrolled) this.scrolled = true;
                else if (window.scrollY < 5) this.scrolled = false;
            }
        },
    }
</script>

<style scoped lang="scss">
    .mobilenav {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        @apply bg-transparent;
        &.active {
            @apply bg-teilor;
            .bar1, .bar2, .bar3 {
                @apply bg-white;
            }
        }
        padding: 12px 24px;
        display: flex;
        justify-content: flex-end;
        z-index: 99;
    }
    .burgerMenu {
        //position: fixed;
        top: 24px;
        right: 24px;
    }

    .bar1, .bar2, .bar3 {
        width: 30px;
        height: 4px;
        @apply bg-teilor;
        //@apply bg-teilor;
        //@apply bg-white;
        //@apply bg-teilor;
        //&.active {
        //    @apply bg-white;
        //}
        margin: 4px 0;
        transition: 0.4s;
    }

    .menuOpen .bar1 {
        -webkit-transform: rotate(-45deg) translate(-7px, 3px);
        transform: rotate(-45deg) translate(-7px, 3px);
    }

    .menuOpen .bar2 { opacity: 0; }

    .menuOpen .bar3 {
        -webkit-transform: rotate(45deg) translate(-7px, -5px);
        transform: rotate(45deg) translate(-7px, -5px);
    }
    // burger menu
    .navbar {
        height: 4rem;
        z-index: 9997;
        top: 0;
        @screen desktop {
            top: 2.5rem;
        }
        -webkit-box-shadow: 0px 6px 20px 0px rgba(56,56,56,0.30);
        box-shadow: 0px 6px 20px 0px rgba(56,56,56,0.30);
    }
</style>