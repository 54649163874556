<template>
    <div id="OnlineStores" class="my-32 desktop:block flex flex-col">
        <div class="flex">
            <div class="top-left justify-end link-title mr-6 MontserratMedium">
                <span class="desktop:hidden">TEILOR</span>
                <span class="hidden desktop:inline">TEILOR</span>
                <span class="hidden desktop:block h-4 w-2px bg-black mx-2" />
                <span class="text-teilor hidden desktop:inline">ONLINE STORES</span>
            </div>

            <div class="top-right hidden desktop:block" />
            <div class="top-right desktop:hidden link-title flex items-center justify-center MontserratMedium">
                <span class="text-teilor">ONLINE STORES</span>
            </div>
        </div>

        <div class="flex desktop:flex-row flex-col">
            <div class="right flex desktop:hidden relative z-10">
                <div class="img-container flex flex-col w-full">
                    <!--                    <img src="../../assets/teilor2.png" alt="">-->
                    <!--                    <img src="../../assets/teilor2.png" alt="">-->
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq1">
                        <a href="https://teilor.ro" target="_blank">TEILOR.RO</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq2">
                        <a href="https://teilor.bg" target="_blank">TEILOR.BG</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq3">
                        <a href="https://teilor.pl" target="_blank">TEILOR.PL</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq4">
                        <a href="https://teilor.com" target="_blank">TEILOR.COM</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq5">
                        <a href="https://teilor.hu" target="_blank">TEILOR.HU</a>
                    </div>
                </div>
            </div>

            <div class="flex desktop:hidden">
                <div class="bottom-left mr-6" />
                <div class="bottom-right" />
            </div>

            <div class="left flex">
                <div class="relative max-w-tablet z-10 text-content text-lg MontserratLight">
                    <span class="absolute page-number">04</span>
                    <p class="my-6">
                        The digital presence of the brand reflects the spirit of physical stores, through precious
                        pieces of jewellery & a unique journey for each customer. TEILOR currently operates five
                        e-commerce platforms – teilor.com, teilor.ro, teilor.hu, teilor.bg and teilor.pl.
                    </p>
                </div>
            </div>

            <div class="right hidden desktop:flex relative z-10">
                <div class="img-container flex flex-col w-full">
<!--                    <img src="../../assets/teilor2.png" alt="">-->
<!--                    <img src="../../assets/teilor2.png" alt="">-->
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq1">
                        <a href="https://teilor.ro" target="_blank">TEILOR.RO</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq2">
                        <a href="https://teilor.bg" target="_blank">TEILOR.BG</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq3">
                        <a href="https://teilor.pl" target="_blank">TEILOR.PL</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq4">
                        <a href="https://teilor.com" target="_blank">TEILOR.COM</a>
                    </div>
                    <div class="h-1/5 w-full cursor-pointer flex items-center justify-center px-5 py-8 text-white MontserratMedium sq5">
                        <a href="https://teilor.hu" target="_blank">TEILOR.HU</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="hidden desktop:flex">
            <div class="bottom-left mr-6" />
            <div class="bottom-right" />
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        // this.$nextTick(() => {
        //     console.log(this.$route.hash);
        //     const el = document.querySelector(this.$route.hash);
        //     console.log(el);
        //     el && el.scrollIntoView({ behavior: "smooth" });
        // })
    }
}
</script>

<style scoped lang="scss">
.right {
    @apply bg-darkGray;
    width: 100%;
    @screen desktop {
        width: 40%;
    }
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    .img-container {
        height: 100%;
        transform: translate(2%, 0);
        @screen desktop {
            transform: translate(-15%, 0);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: -7px;
            height: 7px;
            width: 250px;
            @apply bg-teilor;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -7px;
            width: 7px;
            height: 150px;
            @apply bg-teilor;
        }
    }
    img {
        object-fit: cover;
        width: 98%;
        @screen desktop {
            height: 100%;
            width: unset;
        }
    }
}
.left {
    //@apply bg-light-gray;
    padding: 20px 6vw;
    width: 100%;
    @screen desktop {
        padding: unset;
        width: calc(60% - 1.5rem);
    }
    .text-content {
        @screen desktop {
            margin-right: 20%;
            margin-left: calc(min(5vw, 15%));
        }
    }
}
.top-left, .top-right,.bottom-right, .bottom-left {
    height: 60px;
}
.top-right, .bottom-right {
    span {
        width: min-content;
    }
    width: 40%;
    @apply bg-darkGray;
}

.top-left, .bottom-left {
    width: calc(60% - 1.5rem);
    @screen desktop {
        width: calc(60% - 3rem);
    }
}

.page-number {
    font-size: 200px;
    line-height: 200px;
    @apply text-number-gray;
    top: -100px;
    left: 0;
    @screen desktop {
        left: -50px;
    }
    z-index: -1;
}

    .sq1 {
        background-color: #85776f;
    }
    .sq2 {
        background-color: #908178;
    }
    .sq3 {
        background-color: #9b8b82;
    }
    .sq4 {
        background-color: #a4948a;
    }
    .sq5 {
        background-color: #ad9c92;
    }
p {
    text-align: justify;
}
</style>