<template>
    <div id="rezultate_financiare" class="main-container">
        <h2 v-if="language.toUpperCase() == 'RO'" class="text-teilor MontserratMedium">Rezultate financiare</h2>
        <h2 v-else class="text-teilor MontserratMedium">Financial Results</h2>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            Vă invităm să consultați cele mai recente rapoarte financiare ale Teilor Holding S.A.. Compania
            publică rezultatele financiare semestrial. Pentru mai multe informații despre data publicării
            rapoartelor, vă invităm să consultați Calendarul Financiar</p>
        <p class="MontserratLight" v-else>
            We invite you to consult the latest financial reports of Teilor Holding S.A. The company
            publishes the financial results every six months. For more information on the date of publication
            of the reports, please see the Financial Calendar
        </p>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        }
        // name: '',
        // components: '',
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {},
        // methods: {}
    };
</script>

<style scoped lang='scss'>

</style>
