<template>
    <div id="MGMapOfStores" class="desktop:block flex flex-col">
        <div class="flex">
            <div class="top-left hide hidden desktop:block" />
            <div class="top-left mobile desktop:hidden link-title flex items-center justify-center MontserratMedium">
                <span>MONEYGOLD</span>
            </div>
            <div class="top-right ml-6 link-title MontserratMedium">
                <span class="hidden desktop:inline">MONEYGOLD</span>
                <span class="hidden desktop:block h-4 w-2px bg-black mx-2" />
                <span class="text-teilor">MAP OF STORES</span>
            </div>
        </div>

        <div class="flex desktop:flex-row flex-col">
            <div class="left relative z-10">
                <div class="img-container">
<!--                    <img src="../../assets/mg3.jpg" alt="">-->
                    <img src="../../assets/mg3-2.png" alt="">
                </div>
            </div>

            <div class="flex desktop:hidden">
                <div class="bottom-left" />
                <div class="bottom-right" />
            </div>

            <div class="right flex items-end justify-center">
                <div class="relative max-w-tablet z-10 text-content text-lg MontserratLight">
                    <span class="absolute page-number">03</span>
                    <p class="my-6">
                        Our locations are designed to offer you great customer experience, providing a space in which you feel comfortable and safe!
                    </p>
                </div>
            </div>
        </div>

        <div class="hidden desktop:flex">
            <div class="bottom-left" />
            <div class="bottom-right" />
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            // this.$nextTick(() => {
            //     console.log(this.$route.hash);
            //     const el = document.querySelector(this.$route.hash);
            //     console.log(el);
            //     el && el.scrollIntoView({ behavior: "smooth" });
            // })
        }
    }
</script>

<style scoped lang="scss">
.left {
    @apply bg-darkGray;
    width: 100%;
    @screen desktop {
        width: 40%;
    }
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .img-container {
        height: 100%;
        transform: translate(-2%, 0);
        @screen desktop {
            transform: translate(15%, 0);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: -7px;
            right: -7px;
            height: 7px;
            width: 250px;
            @apply bg-teilor
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -7px;
            width: 7px;
            height: 150px;
            @apply bg-teilor
        }
    }
    img {
        object-fit: cover;
        object-position: 0 50%;
        margin-left: auto;
        width: 98%;
        @screen desktop {
            margin-left: unset;
            height: 100%;
            width: unset;
        }
    }
}
.right {
    @apply bg-light-gray;
    padding: 20px 6vw;
    width: 100%;
    @screen desktop {
        padding: unset;
        width: calc(60% - 1.5rem);
    }
    .text-content {
        @screen desktop {
            margin-right: calc(min(5vw, 15%));
            margin-left: 20%;
        }
    }
}
.top-right, .top-left,.bottom-left, .bottom-right {
    height: 60px;
}
.top-left, .bottom-left {
    width: 40%;
    @apply bg-darkGray;
}

.top-right, .bottom-right {
    width: calc(60% - 1.5rem);
    background-color: white;
}

.page-number {
    font-size: 200px;
    line-height: 200px;
    @apply text-number-gray;
    top: -100px;
    right: 0;
    @screen desktop {
        right: -50px;
    }
    z-index: -1;
}
p {
    text-align: justify;
}
</style>