<template>
    <div class="sidebar">
<!-- <div class="main-grid"> -->
        <div class="select">
            <label for="selectLanguage" class="MontserratMedium">{{ language == 'RO' ? 'Limba' : 'Language' }}</label>
            <select class="ml-2 MontserratLight" id="selectLanguage" :value="language" @input="changeLanguage($event.target.value)">
                <option value="RO">RO</option>
                <option value="EN">EN</option>
            </select>
        </div>
        <div class="sidebar-inner">
            <div class="mt-8">
                <router-link id="teilor-link" @click="$emit('closeSidebar')" class="main-link MontserratMedium text-teilor" :to="{ name:'teilor', hash:'#AboutUs', params: { language } }">TEILOR</router-link>
                <ul class="pt-2 ml-4 MontserratLight">
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'teilor', hash:'#AboutUs' }">ABOUT US</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'teilor', hash:'#History' }">HISTORY</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'teilor', hash:'#MapOfStores' }">MAP OF STORES</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'teilor', hash:'#OnlineStores' }">ONLINE STORES LINKS</router-link></li>
                </ul>
            </div>

            <div class="mt-10">
                <router-link @click="$emit('closeSidebar')" id="moneygold-link" class="main-link MontserratMedium text-teilor" :to="{ name:'mg', hash:'#AboutMoneyGold', params: { language } }">MONEYGOLD</router-link>
                <ul class="pt-2 ml-4 MontserratLight">
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'mg', hash:'#AboutMoneyGold', params: { language } }">ABOUT US</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'mg', hash:'#MGHistory', params: { language } }">HISTORY</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'mg', hash:'#MGMapOfStores', params: { language } }">MAP OF STORES</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'mg', hash:'#Values', params: { language } }">OUR VALUES</router-link></li>
                </ul>
            </div>
            <div class="mt-10">
                <router-link @click="$emit('closeSidebar')" id="investors-link" class="main-link MontserratMedium text-teilor" :to="{ name:'inv', hash:'#obligatiuni' }">{{ language == 'RO' ? 'INVESTITORI' : 'INVESTORS' }}</router-link>
                <ul class="pt-2 ml-4 MontserratLight">
                    <!-- <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'inv', hash:'#obligatiuni', params: { language } }">{{ language == 'RO' ? 'OBLIGATIUNI TEILOR HOLDING' : 'TEILOR HOLDING BONDS' }}</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'inv', hash:'#calendar_financiar', params: { language } }">{{ language == 'RO' ? 'CALENDAR FINANCIAR' : 'FINANCIAL CALENDAR' }}</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'inv', hash:'#rezultate_financiare', params: { language } }">{{ language == 'RO' ? 'REZULTATE FINANCIARE' : 'FINANCIAL RESULTS' }}</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'inv', hash:'#rapoarte_curente', params: { language } }">{{ language == 'RO' ? 'RAPOARTE CURENTE' : 'CURRENT REPORTS' }}</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'inv', hash:'#guvernanta_corporativa', params: { language } }">{{ language == 'RO' ? 'GUVERNANTA CORPORATIVA' : 'CORPORATE GOVERNANCE' }}</router-link></li>
                    <li class="py-2" @click="$emit('closeSidebar')"><router-link :to="{ name:'inv', hash:'#ir_contact', params: { language } }">{{ language == 'RO' ? 'IR CONTACT' : 'CONTACT IR' }}</router-link></li> -->
                    <li><a target="_blank" href="/TeilorHolding.pdf">> TEILOR Holding</a></li>
                    <li><a target="_blank" href="/2021AnnualReport.pdf">> 2021 ANNUAL REPORT</a></li>
                    <li><a target="_blank" href="/RaportTeilorHoldingS1-2022.pdf">> 2022-S1 REPORT</a></li>
                    <li><a target="_blank" href="/Raport Anual 2022 VF_small.pdf">> 2022 ANNUAL REPORT</a></li>
                    <li><a target="_blank" href="/Teilor-Holding-2022 Sustainability-Report.pdf">> 2022 SUSTAINABILITY REPORT</a></li>
                    <li><a target="_blank" href="/TEI S1 2023 RO.pdf">> 2023-S1 REPORT</a></li>
                    <li><a target="_blank" href="/Teilor Holding 2023 Annual Report Final_small.pdf">> 2023 ANNUAL REPORT</a></li>
                    <li><a target="_blank" href="/Raport semestrial Teilor Holding S1-2024_small.pdf">> 2024-S1 REPORT</a></li>
                </ul>
            </div>
        </div>
<!-- </div> -->
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        },
        methods: {
            changeLanguage (val) {
                var { name, hash } = this.$route;
                this.$store.commit('changeLanguage', val);
                this.$router.push({ name, hash, params: { language: val } });
            }
        }
    }
</script>

<style scoped lang="scss">
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        max-height: 100vh;
        overflow-y: scroll;
        height: 103vh;
        width: 70vw;
        background-color: white;
        //border-right-style: solid;
        //border-right-width: 2px;
        //@apply border-teilor;
    }

    .sidebar-inner {
        width: fit-content;
        margin-left: 30px;
        //margin-left: calc(min(26px, auto));
        //margin-right: auto;
    }
    .select {
        margin-left: 30px;
        margin-top: 30px;
    }
</style>
