<template>
    <div id="Values" class="my-32 desktop:block flex flex-col">
        <div class="flex">
            <div class="top-left justify-end link-title mr-6 MontserratMedium">
                <span class="desktop:hidden">MONEYGOLD</span>
                <span class="hidden desktop:inline">MONEYGOLD</span>
                <span class="hidden desktop:block h-4 w-2px bg-black mx-2" />
                <span class="text-teilor hidden desktop:inline">OUR VALUES</span>
            </div>
            <div class="top-right hidden desktop:block" />
            <div class="top-right desktop:hidden link-title flex items-center justify-center MontserratMedium">
                <span class="text-teilor">OUR VALUES</span>
            </div>
        </div>

        <div class="flex desktop:flex-row flex-col">
            <div class="right flex desktop:hidden relative z-10">
                <div class="img-container">
                    <img src="../../assets/mg4.png" alt="">
                </div>
            </div>

            <div class="flex desktop:hidden">
                <div class="bottom-left mr-6" />
                <div class="bottom-right" />
            </div>

            <div class="left flex items-end justify-center">
                <div class="relative max-w-tablet z-10 text-content text-lg MontserratLight">
                    <span class="absolute page-number">04</span>

                    <p class="my-6">
                        From the beginning, our goal has been to offer services based on trust, professionalism, seriousness and rapidity, and we have differentiated ourselves by maintaining a low commission, of just 0.1% per day, regardless of the amount borrowed, a grace period of 90 days and other advantageous conditions for credits offered. MoneyGold is a valuable brand that offers innovation, quality and always invests in new services delivered at superior standards. On our website you can enjoy the possibility of evaluating your own goods that you want to put up for pawn loans, the status of all your contracts with us or to reserve an amount of money for a money exchange service.
                        Our mission is to deliver fast and secure financial services. This is why you with us you will always find:
                    </p>

                    <ol class="list-decimal">
                        <li>Professionalism – our team has a vast experience and is ready to help</li>
                        <li>Rapidity – we guarantee efficiency when it comes to your needs</li>
                        <li>Security – your belongings are always stored in maximum security conditions</li>
                        <li>Confidentiality – we guarantee a descreet and safe service</li>
                    </ol>

                    <p class="my-6 MontserratMedium">Our team - Receptive, dedicated, passioned</p>

                    <p class="">
                        MoneyGold team is comprised of over 150 people – a dynamic and creative team involved in delivering high quality services.
                        By servicing people around the country, we have a day-by-day opportunity to learn, adapt and improve our services.
                        The team values align with the company values and they are based on trust, integrity,
                        performance and continuous improvement. The pawning services are operated by
                        INVEST INTERMED GF IFN S.R.L., the money transfer and exchange services are operated by TEILOR INVEST EXCHANGE SRL.
                    </p>
                </div>
            </div>

            <div class="right hidden desktop:flex relative z-10">
                <div class="img-container">
                    <img src="../../assets/mg4.png" alt="">
                </div>
            </div>
        </div>

        <div class="hidden desktop:flex">
            <div class="bottom-left mr-6" />
            <div class="bottom-right" />
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        // this.$nextTick(() => {
        //     console.log(this.$route.hash);
        //     const el = document.querySelector(this.$route.hash);
        //     console.log(el);
        //     el && el.scrollIntoView({ behavior: "smooth" });
        // })
    }
}
</script>

<style scoped lang="scss">
ol {
    margin-left: 18px;
}
.right {
    @apply bg-darkGray;
    width: 100%;
    @screen desktop {
        width: 40%;
    }
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    .img-container {
        height: 100%;
        transform: translate(2%, 0);
        @screen desktop {
            transform: translate(-15%, 0);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: -7px;
            height: 7px;
            width: 250px;
            @apply bg-teilor
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -7px;
            width: 7px;
            height: 150px;
            @apply bg-teilor
        }
    }
    img {
        object-fit: cover;
        width: 98%;
        @screen desktop {
            height: 100%;
            width: unset;
        }
    }
}
.left {
    padding: 20px 6vw;
    @apply bg-light-gray;
    width: 100%;
    @screen desktop {
        padding: unset;
        width: calc(60% - 1.5rem);
    }
    .text-content {
        @screen desktop {
            margin-right: 20%;
            margin-left: calc(min(5vw, 15%));argin-left: 20%;
        }
    }
}
.top-left, .top-right,.bottom-right, .bottom-left {
    height: 60px;
}
.top-right, .bottom-right {
    width: 40%;
    @apply bg-darkGray;
}

.top-left, .bottom-left {
    width: calc(60% - 1.5rem);
    @screen desktop {
        width: calc(60% - 3rem);
    }
}

.page-number {
    font-size: 200px;
    line-height: 200px;
    @apply text-number-gray;
    top: -100px;
    left: 0;
    @screen desktop {
        left: -50px;
    }
    z-index: -1;
}
p {
    text-align: justify;
}
</style>