<template>
    <div id="History" class="my-32 desktop:block flex flex-col">
        <div class="flex">
            <div class="top-left justify-end link-title mr-6 MontserratMedium">
                <span class="desktop:hidden">TEILOR</span>
                <span class="hidden desktop:inline">TEILOR</span>
                <span class="hidden desktop:block h-4 w-2px bg-black mx-2" />
                <span class="text-teilor hidden desktop:inline">HISTORY</span>
            </div>

            <div class="top-right hidden desktop:block" />
            <div class="top-right desktop:hidden link-title flex items-center justify-center MontserratMedium">
                <span class="text-teilor">HISTORY</span>
            </div>
        </div>

        <div class="flex desktop:flex-row flex-col">
            <div class="right flex desktop:hidden relative z-10">
                <div class="img-container">
                    <img src="../../assets/teilor2.png" alt="">
                </div>
            </div>

            <div class="flex desktop:hidden">
                <div class="bottom-left mr-6" />
                <div class="bottom-right" />
            </div>

            <div class="left flex">
                <div class="relative max-w-tablet z-10 text-content text-lg MontserratLight">
                    <span class="absolute page-number">02</span>
<!--                    <p class="my-6">-->
                    <div class="">
                        <p>1998</p>
                        <p>The brand was born.</p>
                   </div>

                    <div class="my-4">
                        <p>2002</p>
                        <p>TEILOR wedding brands collection launched</p>
                    </div>
                    <div class="my-4">
                        <p>2004</p>
                        <p>The first awarded TEILOR Ad Campaign</p>
                    </div>
                    <div class="my-4">
                        <p>2008</p>
                        <p>A new TEILOR Headquarter</p>
                    </div>
                    <div class="my-4">
                        <p>2005</p>
                        <p>Launching the first TEILOR Fine Jewellery Collection</p>
                    </div>
                    <div class="my-4">
                        <p>2013</p>
                        <p>Launching the first TEILOR store in Bucharest, at Baneasa Shopping City</p>
                    </div>
                    <div class="my-4">
                        <p>2016</p>
                        <p>TEILOR Exclusive 0 the opening of the first Exclusive store</p>
                    </div>
                    <div class="my-4">
                        <p>2018</p>
                        <p>TEILOR first collection with GIA Certification</p>
                    </div>
                    <div class="my-4">
                        <p>2019</p>
                        <p>Reaching the number of 50 stores across Romania</p>
                    </div>
                    <div class="my-4">
                        <p>2020</p>
                        <p>TEILOR is top of mind high jewelley brand and market leader In e-commerce in Romania</p>
                    </div>
                    <div class="">
                        <p>2021</p>
                        <p>International expansion continues: launching 2 new stores in Bulgaria, one in Hungary.</p>
                    </div>


<!--                    </p>-->
<!--                    <p class="my-6">-->
<!--                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the-->
<!--                        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of-->
<!--                        type and scrambled it to make a type specimen book. It has survived not only five centuries, but also-->
<!--                        the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s-->
<!--                        with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop-->
<!--                        publishing software like Aldus PageMaker including versions of Lorem Ipsum.-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the-->
<!--                        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of-->
<!--                        type and scrambled it to make a type specimen book. It has survived not only five centuries, but also-->
<!--                        the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s-->
<!--                        with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop-->
<!--                        publishing software like Aldus PageMaker including versions of Lorem Ipsum.-->
<!--                    </p>-->
                </div>
            </div>

            <div class="right hidden desktop:flex relative z-10">
                <div class="img-container">
                    <img src="../../assets/teilor2.png" alt="">
                </div>
            </div>
        </div>

        <div class="hidden desktop:flex">
            <div class="bottom-left mr-6" />
            <div class="bottom-right" />
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            // this.$nextTick(() => {
            //     console.log(this.$route.hash);
            //     const el = document.querySelector(this.$route.hash);
            //     console.log(el);
            //     el && el.scrollIntoView({ behavior: "smooth" });
            // })
        }
    }
</script>

<style scoped lang="scss">
.right {
    //background-color: #dae0e3;
    @apply bg-darkGray;
    width: 100%;
    @screen desktop {
        width: 40%;
    }
    position: relative;
    //&:not(.hidden) {
    //    display: flex;
    //}
    justify-content: flex-start;
    align-items: flex-start;
    .img-container {
        height: 100%;
        transform: translate(2%, 0);
        @screen desktop {
            transform: translate(-15%, 0);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: -7px;
            height: 7px;
            width: 250px;
            @apply bg-teilor
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -7px;
            width: 7px;
            height: 150px;
            @apply bg-teilor
        }
    }
    img {
        object-fit: cover;
        width: 98%;
        @screen desktop {
            height: 100%;
            width: unset;
        }
    }
}
.left {
    //@apply bg-light-gray;
    padding: 20px 6vw;
    width: 100%;
    @screen desktop {
        padding: unset;
        width: calc(60% - 1.5rem);
    }
    .text-content {
        @screen desktop {
            margin-right: 20%;
            margin-left: calc(min(5vw, 15%));
        }
    }
}
.top-left, .top-right,.bottom-right, .bottom-left {
    height: 60px;
}
.top-right, .bottom-right {
    width: 40%;
    @apply bg-darkGray;
}
.top-left, .bottom-left {
    width: calc(60% - 1.5rem);
    @screen desktop {
        width: calc(60% - 3rem);
    }
}

.page-number {
    font-size: 200px;
    line-height: 200px;
    @apply text-number-gray;
    top: -100px;
    left: 0;
    @screen desktop {
        left: -50px;
    }
    z-index: -1;
}
p {
    text-align: justify;
}
</style>