<template>
    <main>
        <AboutUs/>
        <History />
        <MapOfStores />
        <OnlineStores />
    </main>
</template>

<script>
    import AboutUs from './Teilor/1AboutUs';
    import History from './Teilor/2History';
    import MapOfStores from './Teilor/3MapOfStories';
    import OnlineStores from './Teilor/4OnlineStores'
    
    export default {
        components: { AboutUs, History, MapOfStores, OnlineStores }
    }
</script>

<style scoped lang="scss">
    main {
        background: url('./../assets/wallpaper.jpg') repeat;
    }
</style>
