<template>
    <div id="banner" class="relative">
<!--        <div class="bg-teilor w-full h-3 absolute top-0 z-10" />-->
        <p class="teilorholding absolute text-teilor font-bold text-xl top-1/10 left-5vw">TEILORHOLDING</p>

        <!-- <div v-if="!isMobile" class="select">
            <label for="selectLanguage" class="MontserratMedium">{{ language == 'RO' ? 'Limba' : 'Language' }}</label>
            <select class="ml-2 MontserratLight" id="selectLanguage" :value="language" @input="changeLanguage($event.target.value)">
                <option value="RO">RO</option>
                <option value="EN">EN</option>
            </select>
        </div> -->

        <DesktopNAV class="hidden desktop:flex" />
        <MobileNAV class="desktop:hidden" @toggle_sidebar="sidebar_is_open = !sidebar_is_open" :sidebar_is_open="sidebar_is_open" />

        <transition name="slide-sidebar">
            <MobileSidebar v-if="sidebar_is_open" @closeSidebar="sidebar_is_open = false" />
        </transition>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DesktopNAV from "./DesktopNAV";
    import MobileNAV from './MobileNav';
    import MobileSidebar from './MobileSidebar';
    import { mapGetters, useStore  } from 'vuex';
    // import { useRouter } from 'vue-router';
    import { useRouter, useRoute } from 'vue-router'

    export default {
        components: { MobileNAV, DesktopNAV, MobileSidebar },

        mounted () {
            console.log('this.$route', this.$route);
        },

        computed: {
            ...mapGetters(['language']),
            isMobile () {
                    return window.screen.width < 1024;
            }
        },

        setup () {
            var sidebar_is_open = ref(false);
            var store = useStore();
            var router = useRouter();
            var route = useRoute();
            function changeLanguage (val) {
                store.commit('changeLanguage', val);
                router.push({ name: route.name, hash: route.hash, params: { language: val } });
            }

            return {
                sidebar_is_open,
                changeLanguage
            }
        }
    }
</script>

<style scoped lang="scss">
    #banner {
        height: 100vh;
        background-position: 36% 22%;
        @screen desktop {
            background-position: 50% 88%;
        }
        background-image: url('../../assets/catrinel.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        //position: relative;
        @screen xl {
        }
        @screen xxl {
            //height: 80vh;
        }
        @screen 3xl {
            //height: 60vh;
        }

    }
    //.teilorholding {
    //
    //}
    .slide-sidebar-enter-from, .slide-sidebar-leave-to {
        transform: translateX(-70vw);
        //transform: translateX(-70vw);
        opacity: 0;
    }
    .slide-sidebar-enter-active {
        transition: all 0.4s ease-out;
        transition-timing-function: cubic-bezier(0.05, .05, .3, 1.40);
        will-change: transform;
    }
    .slide-sidebar-leave-active {
        transition: all 0.4s ease-in;
        will-change: transform;
    }
    .select {
        position: absolute;
        top: 12px;
        right: 30px;
        border: none;
        option {
            border: none;
        //    width: 20px;
        //    height: 20px;
        //    background-size: cover;
            background-color: white;
        }
    }

</style>
