<template>
    <div id="obligatiuni" class="flex flex-col desktop:flex-row items-center main-container">
        <div class="content">
            <h2 v-if="language.toUpperCase() == 'RO'" class="text-teilor MontserratMedium">Despre companie</h2>
            <h2 v-else class="text-teilor MontserratMedium">About the company</h2>

            <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                    TEILOR Holding S.A. este o entitate înființată în 2021, care reunește sub o singură umbrelă toate afacerile
                antreprenorilor români Florin și Geanina Enache. Holdingul are un capital social de 350,5 milioane de lei și este asociat
                unic al TEILOR SRL, lanț de magazine de bijuterii de lux prezent în România, Polonia, Ungaria și Bulgaria, Teilor Invest
                Exchange SRL, o rețea de exchange offices, și Invest Intermed GF IFN SRL, o instituție financiară ne-bancară care
                desfășoară o activitate de intermediere financiară în sistem de împrumut pe termen scurt cu gaj, ultimele operând
                împreună sub marca MoneyGold.
            </p>
            <p class="MontserratLight" v-else>
                TEILOR Holding S.A. is an entity established in 2021, uniting under one umbrella all businesses of Rumanian
                entrepreneurs Florin and Geanina Enache. The holding company has a share capital of 350.5 million lei, and it is the
                sole shareholder in TEILOR SRL, a chain of luxury jewelry stores present in Romania, Poland, Hungary and Bulgaria,
                Teilor Invest Exchange SRL, a network of exchange offices, and Invest Intermed GF IFN SRL, a non-bank financial
                institution which carries out short-term financial lending activity with a pledge, with the last two companies operating
                together under the MoneyGold brand.
            </p>

            <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                    Anterior listării pe piața AeRO a Bursei de Valori București, TEILOR Holding S.A. a închis cu succes prima emisiune de
                obligațiuni corporative și a atras 45 de milioane de lei de la investitorii BVB. Banii au fost atrași prin intermediul a două
                plasamente private, unul în euro și altul în lei, ce s-au desfășurat în perioada 18 – 26 noiembrie 2021.
            </p>
            <p class="MontserratLight" v-else>
                Prior to listing on the AeRO market of the Bucharest Stock Exchange, TEILOR Holding S.A. successfully closed the
                first corporate bonds issuance and raised a total of 45 million lei from BVB investors. The money was attracted through
                two private placements, one in euro and one in lei, which took place between November 18 and 26, 2021.
            </p>

            <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Obligațiuni TEI26</h3>
            <h3 class="MontserratMedium" v-else>TEI26 Bonds</h3>


            <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                    Obligațiunile corporative emise în lei au o valoare nominală de 100 lei pe obligațiune, maturitate la 5 ani și o rată a
                dobânzii fixe de 8,25%, cu plata a cuponului semestrială. Emisiunea a fost emisă în data de 3 decembrie 2021 și are
                data de scadență 3 decembrie 2026.
            </p>
            <p class="MontserratLight" v-else>
                The corporate bonds issued in lei have a nominal value of 100 lei per bond, 5-years maturity and a fixed interest rate of
                8.25%, with half-yearly payment of the coupon. The issue was issued on December 3, 2021 and has a maturity date on
                December 3, 2026.
            </p>

            <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                Cele 140.035 de obligațiuni corporative denominate în lei, în valoare totală de peste 14 milioane de lei au intrat la
            tranzacționare pe segmentul SMT-Bonds al Bursei de Valori București în data de 21 decembrie 2021, sub simbolul
            TEI26. Pentru informații mai detaliate cu privire la evoluția obligațiunilor TEI26 pe piața de capital vă invităm să urmăriți
            profilul nostru de pe site-ul Bursei de Valori București, disponibil
                <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26" target="_blank">AICI</a>.
            </p>
            <p class="MontserratLight" v-else>
                The 140,035 corporate bonds denominated in lei, in a total value of over 14 million lei debuted on the SMT-Bonds
                segment of the Bucharest Stock Exchange on December 21, 2021, under TEI26 symbol. For more detailed information
                on the evolution of TEI26 bonds on the capital market, we invite you to follow our profile on the website of the
                Bucharest Stock Exchange, available
                <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26" target="_blank">HERE</a>.
            </p>

            <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Obligațiuni TEI26E</h3>
            <h3 class="MontserratMedium" v-else>TEI26E Bonds</h3>

            <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                Obligațiunile corporative denominate în euro au o valoare nominală de 100 de euro pe obligațiune, scadență la 5 ani și
            o dobândă fixă de 5,75%, plătibilă semestrial. Emisiunea a fost emisă în data de 3 decembrie 2021 și are data de
            scadență 3 decembrie 2026.
            </p>
            <p class="MontserratLight" v-else>
                The euro-denominated corporate bonds have a nominal value of 100 euro per bond, 5-years maturity and a fixed
                interest rate of 5.75%, payable half-yearly. Emisiunea a fost emisă în data de 3 decembrie 2021 și are data de
                scadență 3 decembrie 2026.
            </p>

            <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                Cele 62.700 de obligațiuni corporative denominate în euro în valoare totală de 6,27 milioane de euro au intrat la
            tranzacționare pe segmentul SMT-Bonds al Bursei de Valori București în data de 21 decembrie 2021, sub simbolul
            TEI26E. Pentru informații mai detaliate cu privire la evoluția obligațiunilor TEI26E pe piața de capital vă invităm să
            urmăriți profilul nostru de pe site-ul Bursei de Valori București, disponibil
                <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26E" target="_blank">AICI</a>.
            </p>
            <p class="MontserratLight" v-else>
                The 62,700 euro-denominated corporate bonds totaling 6.27 million euro debuted on the SMT-Bonds segment of the
                Bucharest Stock Exchange on December 21, 2021, under TEI26E symbol. For more detailed information on the
                evolution of TEI26E bonds on the capital market, we invite you to follow our profile on the website of the Bucharest
                Stock Exchange, available
                <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26E" target="_blank">HERE</a>..
            </p>
        </div>
<!--        <Chart />-->
    </div>
</template>

<script>
    // import Chart from '../Chart2';
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        }
        // name: '',
        // components: { Chart },
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {
        //     this.renderChart(this.chartdata, this.options)
        // }
        // methods: {}
    };
</script>

<style scoped lang='scss'>
</style>
