<template>
    <div>
        <AboutUs />
        <History />
        <MapOfStores />
        <OurValues />
    </div>
</template>

<script>
    import AboutUs from './MoneyGold/1AboutUs'
    import History from './MoneyGold/2History'
    import MapOfStores from './MoneyGold/3MapOfStories'
    import OurValues from './MoneyGold/4OnlineStores'

    export default {
        components: { AboutUs, History, MapOfStores, OurValues }
    }
</script>

<style scoped lang="scss">

</style>