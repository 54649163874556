<template>
    <div>
        <Bonds />
        <Calendar />
        <Results />
        <Reports />
        <Governance />
        <IrContact />
    </div>
</template>

<script>
    import Bonds from './Investors/1Bonds';
    import Calendar from './Investors/2Calendar';
    import Results from './Investors/3Results';
    import Reports from './Investors/4Reports';
    import Governance from './Investors/5Governance';
    import IrContact from './Investors/6IrContact';

    export default {
        // name: '',
        components: {
            Bonds, Calendar, Results, Reports, Governance, IrContact
        },
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {},
        // methods: {}
    };
</script>

<style scoped lang='scss'>
    .main-container {
        margin-left: calc(min(5vw, 15%));
        margin-right: calc(min(5vw, 15%));
        margin-top: 50px;
    }
</style>
