<template>
    <div id="ir_contact" class="main-container mb-8">
        <h2 class="text-teilor MontserratMedium">Contact IR</h2>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            Pentru orice întrebări legate de activitatea Teilor Holding S.A.,
            planurile noastre de piața de capital, precum și relația cu
            investitorii, vă rugăm să contactați echipa de relații cu
            investitorii la:
        </p>
        <p class="MontserratLight" v-else>
            For any questions regarding the activity of Teilor Holding S.A., our
            capital market plans, as well as the investor relationship, please
            contact the investor relations team at:
        </p>
        <div class="flex justify-between w-full desktop:w-72 mt-1 mb-1 MontserratLight">
            <p>Email: </p>
            <a href="mailto:investors@teilorholding.com" class="w-40 font-bold text-teilor">investors@teilor.ro</a>
        </div>
        <div class="flex justify-between w-full desktop:w-72 mb-1 MontserratLight">
            <p>Nr telefon: </p>
            <a href="tel:+40318602101" class="w-40 font-bold text-teilor">+40 31 860 21 01</a>
        </div>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            Pentru informații actualizate despre evoluția companiei
            noastre, vă invităm să vizitați pofilele noastre pe site-ul Bursei
            de Valori București, disponibile
            <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26" target="_blank">AICI</a>
            și
            <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26E" target="_blank">AICI</a>.
        </p>
        <p class="MontserratLight" v-else>
            For updated information on the evolution of our company, we invite
            you to visit our profiles on the website of the Bucharest Stock
            Exchange, available
            <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26" target="_blank">HERE</a>
            and
            <a class="external-link" href="https://bvb.ro/FinancialInstruments/Details/FinancialInstrumentsDetails.aspx?s=TEI26E" target="_blank">HERE</a>.
        </p>

        <div class="flex flex-col xl:flex-row justify-between mt-4">
            <div class="xl:w-1/2 w-full">
                <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Newsletter Investitori</h3>
                <h3 class="MontserratMedium" v-else>Investor Newsletter</h3>
                <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
                Vă invităm să vă abonați la newsletter-ul nostru pentru a primi update-uri periodice
                    despre Teilor Holding SA și să ne urmăriți pe canalele noastre de social media.</p>
                <p class="MontserratLight" v-else>
                    We invite you to subscribe to our newsletter to receive regular updates about
                    Teilor Holding SA and to follow us on our social media channels.
                </p>
            </div>

            <div class="flex flex-col w-full xl:w-1/3 my-4">
                <input class="MontserratLight border-0 bg-gray-200 py-2 px-2 rounded" type="text" :placeholder="language == 'RO' ? 'Nume' : 'Name'">
                <input class="MontserratLight border-0 bg-gray-200 py-2 px-2 my-3 rounded" type="text" placeholder="Email">
                <button class="MontserratLight border-0 bg-teilor w-full py-3 flex items-center text-white font-medium justify-center rounded">{{ language == 'RO' ? 'Abonare' : 'Subscribe' }}</button>
            </div>
        </div>

        <div class="flex social-icons mt-6">
            <a href="https://www.linkedin.com/company/teilor/mycompany/" target="_blank"><img src="../../assets/icons/linkedin.png" alt=""></a>
            <a href="https://www.facebook.com/TeilorRomania/" target="_blank"><img src="../../assets/icons/facebook.png" alt=""></a>
            <a href="https://www.instagram.com/teilor_finejewellery/" target="_blank"><img src="../../assets/icons/instagram.png" alt=""></a>
            <a href="https://twitter.com/teilorjewellery" target="_blank"><img src="../../assets/icons/twitter.png" alt=""></a>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        }
        // name: '',
        // components: '',
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {},
        // methods: {}
    };
</script>

<style scoped lang='scss'>
    input {
        outline: none;
    }

    img {
        cursor: pointer;
        margin: 0 10px;
        height: 28px;
    }
    .social-icons {
        width: 100%;
        justify-content: space-between;
        @screen desktop {
            width: unset;
            justify-content: unset;
        }
    }
</style>
