<template>
    <div id="calendar_financiar" class="main-container">
        <h2 v-if="language.toUpperCase() == 'RO'" class="text-teilor MontserratMedium">Calendar financiar</h2>
        <h2 v-else class="text-teilor MontserratMedium">Financial Calendar</h2>

        <div class="flex flex-col xl:flex-row xl:justify-between items-start">
            <div class="grid grid1 w-full xl:w-45% border border-teilor">
                <p class="px-4 bg-teilor leading-50 text-white font-bold">2021</p>
                <div class="mx-4 border-b border-gray-700 grid grid2 py-3">
                    <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">Prima zi de tranzacționare a obligațiunilor</p>
                    <p class="MontserratLight" v-else>Debut on BVB</p>
                    <p class="font-bold MontserratLight">TEI26</p>
                    <p class="MontserratLight">21.12.2021</p>
                </div>
                <div class="mx-4 grid grid2 py-3">
                    <p  class="MontserratLight" v-if="language.toUpperCase() == 'RO'">Prima zi de tranzacționare a obligațiunilor</p>
                    <p  class="MontserratLight" v-else>Debut on BVB</p>
                    <p class="font-bold MontserratLight">TEI26E</p>
                    <p class="MontserratLight">21.12.2021</p>
                </div>
            </div>
            <img src="https://www.safetech.ro/wp-content/uploads/2021/01/shutterstock_502856116.jpg" class="w-full xl:w-45% mt-6 xl:mt-0" />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        }
        // name: '',
        // components: '',
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {},
        // methods: {}
    };
</script>

<style scoped lang='scss'>
    .grid1 {
        grid-template-rows: 50px auto auto;
        //justify-items: center;
        //align-items: center;
        //p {
        //    line-height: 40px;
        //}
    }
    .grid2 {
        //width: 100%;
        grid-template-columns: auto 50px 80px;
        grid-gap: 10px;
        @screen desktop {
            grid-template-columns: auto 70px 150px;
        }
        align-items: center;
        //grid-template-rows: 40px 40px;
        //.tall {
        //    grid-row: 1 / 3;                          /* 5 */
        //    grid-column: 1 / 3;                       /* 5 */
        //}
    }
</style>
