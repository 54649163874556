<template>
    <div id="guvernanta_corporativa" class="main-container">
        <h2 v-if="language.toUpperCase() == 'RO'" class="text-teilor MontserratMedium">Guvernanță Corporativă</h2>
        <h2 v-else class="text-teilor MontserratMedium">Corporate Governance</h2>

        <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Administrator</h3>
        <h3 class="MontserratMedium" v-else>Board of Directors</h3>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            Teilor Holding S.A. este administrată de un Consiliu de Administrație, format din 3 membri cu o durată inițială a mandatelor de 2 ani, fiind numit
            prin act constitutiv. Componența Consiliului de Administrație este următoarea:
        </p>
        <p class="MontserratLight" v-else>
            Teilor Holding S.A. is administered by a Board of Directors, consisting of 3 members with an initial term of office of 2 years, according to the
            Article of Association of the company. The composition of the Board of Directors is as follows:
        </p>
        <ul class="list-disc mt-1 MontserratLight">
            <li class="ml-6">Ilie Enache - Președinte CA & Fondator</li>
            <li class="ml-6">Mircea Varga - Membru CA</li>
            <li class="ml-6">Narcis Stan - Membru CA</li>
        </ul>

        <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Consultant autorizat</h3>
        <h3 class="MontserratMedium" v-else>Authorized Advisor</h3>
        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">Cornerstone Communications SRL este Consultantul Autorizat al Teilor Holding S.A.</p>
        <p class="MontserratLight" v-else>Cornerstone Communications SRL is the Authorized Consultant of Teilor Holding S.A.</p>

        <h3 class="MontserratMedium">Auditor</h3>
        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            Auditorul financiar al Teilor Holding S.A. este PricewaterhouseCoopers Audit S.R.L., cu sediul social în B-dul Poligrafiei nr. 1-T Cladire Ana
            Tower 013704 București, România, cod fiscal 4282940, și este membru al Camerei Auditorilor Financiari din Romania (CAFR) cu număr de
            autorizație 006/25.06.2001.
        </p>
        <p class="MontserratLight" v-else>
            The financial auditor of Teilor Holding S.A. is PricewaterhouseCoopers Audit S.R.L., with its registered office in B-dul Poligrafiei no. 1-T Ana
            Tower Building 013704 Bucharest, Romania, fiscal code 4282940, and is a member of the Romanian Chamber of Financial Auditors (CAFR) with
            authorization number 006 / 25.06.2001.
        </p>

        <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Memorandum listare</h3>
        <h3 class="MontserratMedium" v-else>Listing Memorandum</h3>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">
            • Memorandum pregătit pentru admiterea obligațiunilor emise de Teilor Holding S.A. pe piața AeRO – Descarcă
            <a class="external-link" target="_blank" href="/Memorandum_TEI_F.doc.pdf">AICI</a>
        </p>
        <p class="MontserratLight" v-else>
            Memorandum prepared for the admission of the bonds issued by Teilor Holding S.A. on the AeRO market – Download
            <a class="external-link" target="_blank" href="/Memorandum_TEI_F.doc.pdf">HERE</a>
        </p>

        <h3 class="MontserratMedium" v-if="language.toUpperCase() == 'RO'">Act constitutiv</h3>
        <h3 class="MontserratMedium" v-else>Article of Association</h3>

        <p class="MontserratLight" v-if="language.toUpperCase() == 'RO'">• Act constitutiv Teilor Holding S.A. – <a class="external-link" href="">link</a></p>
        <p class="MontserratLight" v-else>• Article of Associatio of Teilor Holding S.A. - <a class="external-link" href="">link</a></p>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['language'])
        }
        // name: '',
        // components: '',
        // mixins: [],
        // props: {},
        // data: () => ({}),
        // computed: {},
        // watch: {},
        // created () {},
        // mounted () {},
        // methods: {}
    };
</script>

<style scoped lang='scss'>

</style>
